import "jquery"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "@rails/actiontext"
import * as Trix from "trix"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
